<template>
  <ConfigureSlackModal :show="!slackConfigured" />
  <div v-if="slackConfigured && slackConfigured !== 'loading'" class="p-grid">
    <div class="p-col-12">
      <div>
        <h4>End of Day Report</h4>
        <div>
          <Steps :model="items" />
          <router-view
            v-slot="{ Component }"
            @prev-page="handlePrevPage"
            @next-page="handleNextPage"
            @submit-eod="handleSubmitEod"
          >
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, provide, reactive, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'

import useReport from '../../hooks/useReport'
import ConfigureSlackModal from '@/components/custom/ConfigureSlackModal.vue'

export default {
  name: 'EOD',
  components: { ConfigureSlackModal },
  emits: ['prev-page', 'next-page', 'submit-eod'],
  setup() {
    const route = useRoute()
    const router = useRouter()
    const toast = useToast()
    const { error: useReportError, fetchWorklogs, loading, reportData } = useReport()

    // data
    const eodData = reactive({
      overview: {},
      kpis: []
    })
    const workLogs = reactive([])
    const items = reactive([
      {
        label: 'Assignments',
        to: '/reporting/eod'
      },
      {
        label: 'Summary',
        to: '/reporting/eod/summary'
      }
    ])

    // provide/injected properties
    const slackConfigured = inject('slackConfigured')
    provide('workLogs', workLogs)
    provide('workLogsLoading', loading)
    provide('eodData', eodData)
    provide('addOverview', (data) => Object.assign(eodData.overview, data))
    provide('addKpi', (data) => eodData.kpis.push(data))
    provide('clearEodData', () => {
      eodData.overview = {}
      eodData.kpis = []
    })

    // created life cycle hook
    ;(async () => {
      if (route.name !== 'create-eod') router.push({ name: 'create-eod' })
      await fetchWorklogs()
    })()

    watch(reportData, (data) => {
      /** @type {Array<import('../../hooks/useReport').WorkLog>} */
      const logs = data
      if (!logs?.length) return

      // Only inlude worklogs with greater than 5 minutes duration
      const _workLogs = logs.map((task) => ({
        id: task.task_id,
        name: `${task.division_name} - ${task.task_name}`,
        taskName: task.task_name,
        projectName: task.division_name,
        duration: task.duration,
        dynamicForm: { name: task.eod_form?.name, fields: task.eod_form?.fields },
        channel: task.slack_channel,
        channelId: task.slack_channel_id
      }))
      workLogs.push(..._workLogs)
    })

    watch(useReportError, (value) => {
      if (value) {
        const detail = `${value}. Failed to fetch work logs.`
        toast.add({ severity: 'error', summary: 'Error', detail })
        workLogs.values = []
      }
    })

    // methods
    const handleNextPage = (from) => {
      const fromRoute = items.find((el) => el.to === from)
      const index = items.indexOf(fromRoute)
      const toRoute = items[index + 1]
      router.push(toRoute.to)
    }
    const handlePrevPage = (from) => {
      const fromRoute = items.find((el) => el.to === from)
      const index = items.indexOf(fromRoute)
      const toRoute = items[index - 1]
      router.push(toRoute.to)
    }
    const handleSubmitEod = (reportId) => {
      toast.add({ severity: 'success', summary: 'Success', detail: 'End of Day report submitted', life: 3000 })
      router.push({ name: 'eod-detail', params: { reportId } })
    }

    return { eodData, handleNextPage, handlePrevPage, handleSubmitEod, items, slackConfigured, workLogs }
  }
}
</script>

<style scoped>
.btn {
  min-width: 100px;
  min-height: 40px;
}
</style>
